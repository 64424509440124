@import '/src/components/shared/shared.scss';

.app-main-frame {
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    padding:        40px;

    // max-width:  1140px;
    // min-height: 100%;

    /* Brand/1 */
    background: $brand-color;

    /* Inside auto layout */
    order:      0;
    align-self: stretch;
    flex:       80%;
}

.app-head {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    flex-direction:  row;
    width:           100%;
    margin-bottom:   20px;
}

.app-content {
    width:           100%;
    max-height:      80%;
    justify-content: space-evenly;
}

.main-app-part {
    max-width:      500px;
    display:        flex;
    flex-direction: column;
    align-items:    center;
}


.controls-button {
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: center;
    padding:         15px 20px;
    gap:             10px;
    width:           100%;
    margin-top: 20px;

    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   14px;
    line-height: 17px;
    /* Highlight/Bg */

    background:    $regular-color;
    border-radius: 12px;
    border:        0;

    &:hover {
        background-color: $hover-color;
    }

    &:active {
        background-color: $active-color;
    }

    &:disabled {
        background-color: $basic-grey;
    }
}