@import '/src/components/shared/shared.scss';

.app-title {
    color:       $basic-white;
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   32px;
    line-height: 39px;
}

.dices-container{
    position: relative;
    
    & .controls-button{
        max-width: 350px;
    }
}

.controls-button.cancel-button {
    color: $basic-white;
    /* Highlight/Bg */

    background:    none;
    border-radius: 12px;
    border:        1px solid $basic-white;

    &:hover {
        box-shadow: inset 0px 0px 25px 10px rgba(0,0,0,0.31);
    }

    &:active {
        box-shadow: inset 0px 0px 35px 10px rgba(0,0,0,0.71);
    }
}