@import "../shared/shared.scss";

.my-app-root{
    /* Accounts / Desktop */   
    /* Auto layout */  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    
    position: relative;
    width: 100%;
    height: 100%;   
    background: $brand-color;
    color: $basic-white;
}