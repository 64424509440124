@import url('/src/components/shared/shared.scss');

.set-dices-form {
    background-color: grey;
    width:            300px;
    padding:          10px;
    margin:           15px;
    border-radius:    15px;

    .controls-container {
        display:       flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .dices-input {
            box-sizing:     border-box;
            margin-bottom:  0;
            display:        flex;
            flex-direction: row;
            align-items:    flex-start;
            width: 70%;
            padding:        5px 0px 5px 10px;
            background:     #FFFFFF;
            border:         1px solid;
            border-color:   #E6E6E6;
            border-radius:  12px;
        }
    }

    button.controls-button {
        margin-top: 10px;
        background-color: #aaaaaa;
        cursor: pointer;

        &:hover{
            background-color: #cdcdcd;
        }
    }
}