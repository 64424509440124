@import '/src/components/shared/shared.scss';

.dices {
    display:         flex;
    width:           100%;
    justify-content: space-evenly;
    margin:          7% 0;
}

.cube .front {
    transform: translateZ(50px);
}

.cube .back {
    transform: rotateX(-180deg) translateZ(50px);
}

.cube .right {
    transform: rotateY(90deg) translateZ(50px);
}

.cube .left {
    transform: rotateY(-90deg) translateZ(50px);
}

.cube .top {
    transform: rotateX(90deg) translateZ(50px);
}

.cube .bottom {
    transform: rotateX(-90deg) translateZ(50px);
}

.container {
    width:    100px;
    height:   100px;
    position: relative;

    perspective:        1000px;
    perspective-origin: 50% 100%;
}

.cube {
    position: relative;
    width:    100%;
    height:   100%;

    transform-style: preserve-3d;

    transition: transform 2s;
}

.cube .face {
    background: white;
    position:   absolute;

    border-radius: 10%;
    width:         100%;
    height:        100%;

    text-align: center;

    -webkit-box-shadow: inset 0 0 0.5em rgb(67, 66, 66);
    -moz-box-shadow:    inset 0 0 0.5em rgb(67, 66, 66);
    box-shadow:         inset 0 0 0.5em rgb(67, 66, 66);
}

.dot {
    position:         absolute;
    width:            20%;
    height:           20%;
    margin:           -10% 5% 5% -10%;
    border-radius:    50%;
    background-color: #3b3b3b;
    box-shadow:       inset 0 0 0.35em 0.1em rgba(0, 0, 0, 0.75);
}

.front .dot1 {
    top:  50%;
    left: 50%;
}

.back .dot1 {
    top:  20%;
    left: 20%;
}

.back .dot2 {
    top:  80%;
    left: 80%;
}

.right .dot1 {
    top:  20%;
    left: 20%;
}

.right .dot2 {
    top:  50%;
    left: 50%;
}

.right .dot3 {
    top:  80%;
    left: 80%;
}

.left .dot1 {
    top:  20%;
    left: 20%;
}

.left .dot2 {
    top:  20%;
    left: 80%;
}

.left .dot3 {
    top:  80%;
    left: 20%;
}

.left .dot4 {
    top:  80%;
    left: 80%;
}

.top .dot1 {
    top:  20%;
    left: 20%;
}

.top .dot2 {
    top:  20%;
    left: 80%;
}

.top .dot3 {
    top:  50%;
    left: 50%;
}

.top .dot4 {
    top:  80%;
    left: 20%;
}

.top .dot5 {
    top:  80%;
    left: 80%;
}

.bottom .dot1 {
    top:  20%;
    left: 20%;
}

.bottom .dot2 {
    top:  20%;
    left: 50%;
}

.bottom .dot3 {
    top:  20%;
    left: 80%;
}

.bottom .dot4 {
    top:  80%;
    left: 20%;
}

.bottom .dot5 {
    top:  80%;
    left: 50%;
}

.bottom .dot6 {
    top:  80%;
    left: 80%;
}