@import '/src/components/shared/shared.scss';

.app-instruction {
    /* Frame 2 */
    /* Auto layout */
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    padding:        40px;
    gap:            15px;

    // min-width:  300px;
    min-height: 100%;

    color: $basic-white;

    background: $brand-color-aside;

    flex: 20%;
}

.scrollable{
    overflow-y: auto;
}