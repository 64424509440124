@import '/src/components/shared/shared.scss';

.table-container {
    position:      relative;
    overflow:      scroll;
    border-radius: 8px;
    font-size:     14px;

    &::-webkit-scrollbar {
        display: none;
    }

    & .table-header {
        position:        sticky;
        display:         flex;
        justify-content: center;
        width:           100%;
        background:      $brand-color-shaded-dark;
        top:             0px;

        div {
            justify-content: center;
            padding:         10px;
        }

        & .player-points {
            padding:       1px 6px;
            gap:           10px;
            background:    $basic-green;
            color:         $brand-color-shaded;
            border-radius: 6px;
        }
    }

    .table-content {
        background:      $brand-color-shaded;
        border-collapse: collapse;
        max-height:      600px;
        width:           100%;

        & th,
        td {
            padding: 10px 20px;
        }

        & .table-body {
            max-height: 90%;
        }

        & .result-row {
            border-bottom: 1px solid $brand-color;
            width:         100%;

            & .row-number {
                font-weight: 800;
            }

            &.win,
            &.lose,
            &.point {
                & .player-name::after {
                    display:       inline-block;
                    width:         8px;
                    height:        8px;
                    border-radius: 6px;
                    margin-left:   5px;
                }
            }

            &.win {
                & .player-name::after {
                    content:    '';
                    background: $status-positive;
                }
            }
            &.lose {
                & .player-name::after {
                    content:    '';
                    background: $status-negative;
                }
            }
            // &.point {
            //     & .player-name::after {
            //         content:    '';
            //         background: $status-neutral;
            //     }
            // }
        }
    }



}