//inputs-buttons colors
$regular-color:  #24FF61;
$hover-color:    #5DFF24;
$active-color:   #00D93D;
$disabled-color:#E6E6E6;
$invalid-color:  #FF0000;
$focus-color:    #7000FF;

$status-positive: #00E469;
$status-negative: #FF0000;
$status-neutral: #ffd000;

$brand-color: #7000FF;
$brand-color-aside: #6400E3;
$brand-color-shaded: #6100DC;
$brand-color-shaded-dark: #4E01B0;

$basic-white: #FFFFFF;
$basic-grey: #E6E6E6;
$basic-green: #24FF61;