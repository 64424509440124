@import "../colors.scss";
$my-font: 'Montserrat';

@font-face {
    font-family: 'Montserrat';
    src:         url(../../assets/fonts/Montserrat-VariableFont_wght.ttf);
}

* {
    font-family: $my-font;
    box-sizing:  border-box;

    & :not(input) {
        -moz-user-select:    none;
        -ms-user-select:     none;
        -o-user-select:      none;
        -webkit-user-select: none;
        user-select:         none;
    }
}

html,
body,
#root {
    height: 100%;
}

.flex-container {
    display:        flex;
    flex-direction: row;
}

.flex-item {
    flex:   50%;
    margin: 0 20px;
}

@media (max-width: 800px) {
    #root {

        .flex-container {
            flex-direction: column;
            align-items:    center;
            width:          100%;
        }

        .flex-item {
            flex:          auto;
            margin-top:    20px;
            margin-bottom: 0;
            width:         100%;
            max-height:    80%;
        }

        .app-main-frame {
            padding: 20px 0;
        }

        .app-instruction {
            padding: 20px;
        }

        .my-form {
            width: 90%;
        }

        .dices-container {
            order: -1;
        }

        .app-content {
            max-height: 950px;
        }

        .app-instruction {
            min-height: auto;
        }
    }
}